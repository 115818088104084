<template lang="pug">
  main-el.programme-section.flex.justify-end
    //- pre {{ doc }}
    //- h1 PRESS
    panel-left(ref="panel")
      //- , :keepOpen="!panelCollapsed"
      nav.children-mt-lh(role="navigation")
        ul
          li
            a(href='/about#about' data-heading='About') About
          li
            a(href='/about#team' data-heading='Team') Team
          li
            a(href='/about#board' data-heading='Board') Board
          li
            a(href='/about#history' data-heading='History') History
          li
            a(href='/about#partners' data-heading='Partners') Partners
          li
            a(href='/press' data-heading='Press').underline Press
          li
            a(href='/about#opportunities' data-heading='Opportunities') Opportunities
          li
            a(href='/about#support' data-heading='Support') Support

    //- temp col width
    container-panel-active
      //- (intro)
      header.pt-40.px-20.md_px-40.mb-100(v-if="doc && doc.intro")
        .w-full.md_w-8x12.textbody.text-20.xl_text-24(v-html="doc.intro")

      //- (video carousel)
      section
        video-carousel(v-if="doc && doc.videoCarousel", :slides="doc.videoCarousel", ratio="16:9")

      .w-full.flex.flex-wrap
        //- entries...
        template(v-for="(entry, i) in entries" v-if='entries.length')
          //- thumb: large/small (+reverse)
          press-entry-thumb.w-full(:entry="entry", :reverseOld="i % 2 === 0", :key="entry.slug", :size="thumbFormat2(i)[0]", :reverse="thumbFormat2(i)[1]", :class="{'sm_w-1x2': thumbFormat2(i)[0] === 'small'}")

        template(v-if='! entries.length')
          .w-full.textbody.text-20.px-20.md_px-40.mb-40.mt-40
            h1 Our press section is launching soon.

        //- (infinite scroll loader)
        template(v-if="entries.length && !feedEnded")
          observer.h-screen(:threshold="0.4", @visible="getEntries(entries.length)")
            router-link.sr-only(:to="{ query: { page: page + 1 }}") Next Page
</template>

<script>
import PressEntryThumb from '@/components/press/PressEntryThumb'
import VideoCarousel from '@/components/programme/VideoCarousel'
import Observer from '@/components/Observer'
export default {
  name: 'PressIndex',
  data () {
    return {
      entries: [],
      doc: null,
      // page should start at 0 for proper pagination offsetting...
      page: this.$route.query.page || 1,
      pageSize: 20,
      feedEnded: false
    }
  },
  computed: {
    dateKey () {
      return this.$route.name?.includes('-dated') && this.$route.path.split('/').pop()
    },
    dated () {
      if (this.dateKey) {
        const today = ymd(new Date())
        const dateFilters = {
          next: {
            dateStart: [`>${today}`],
            orderBy: 'dateStart ASC'
          },
          now: {
            dateStart: [`<=${today}`],
            dateEnd: [`>${today}`]
          },
          past: {
            dateEnd: [`<${today}`],
            orderBy: 'dateEnd DESC'
          }
        }
        return dateFilters[this.dateKey]
      }
      return undefined
    }
  },
  methods: {
    async getEntries (offset) {
      let params = {
        section: 'press',
        relatedToTags: this.$route.params.tag ? [{ slug: this.$route.params.tag }] : undefined,
        limit: this.pageSize,
        offset: offset || ((this.page - 1) * this.pageSize) // offset will be 0 on first page
      }
      // dated ?
      if (this.dated) {
        params = { ...params, ...this.dated }
      }

      // remove empty params
      Object.keys(params).forEach(key => {
        if (!params[key]) {
          delete params[key]
        }
      })

      // fetch!
      const entries = await this.$store.dispatch('getPressEntries', params)

      if (!entries.length) {
        this.feedEnded = true
      }

      // add
      this.entries = this.entries.concat(entries)
      this.page++
    },
    async getDoc () {
      this.doc = await this.$store.dispatch('getSingle', this.$route.params.section + 'Index')
    },
    thumbFormat (i) {
      const pattern = [
        [undefined, true], // large, img right
        [undefined, false], // large, img left (reverse=true)
        ['small', false], // small img right
        ['small', false],
        ['small', true],
        ['small', true]
      ]
      return pattern[i % 6]
    },
    thumbFormat2 (i) {
      // tagged feeds (read/watch/listen/...) - first two large, the rest small
      if ((this.$route.name.includes('tagged') && i >= 2) || (this.$route.name.includes('press') && i >= 2)) {
        return ['small', (i % 4 === 0 || i % 4 === 1)]
      }
      // default: ALL large
      return [undefined, i % 2 === 0]
    }
  },
  created () {
    // this.getDoc()
    this.getEntries()
  },
  // metaInfo () {
  //   let title = this.$route.params.section || this.$route.params.tag || this.dateKey
  //   const cap = str => str[0].toUpperCase() + str.slice(1)
  //   title = cap(title)
  //   // tagged section ?
  //   if (this.$route.params.section) {
  //     title += this.$route.params.tag ? ` tagged #${this.$route.params.tag}` : ''
  //   }
  //   return {
  //     title: !this.$route.params.section ? `Programme: ${title}` : title,
  //     meta: this.$store.getters.meta({
  //       title,
  //       descrip: this.doc?.metaDescription,
  //       img: this.doc?.metaImage[0]?.url
  //     })
  //   }
  // },
  components: { PressEntryThumb, VideoCarousel, Observer }
}

const ymd = date => `${date.getFullYear()}-${zero(date.getMonth() + 1)}-${zero(date.getDate())}`
const zero = (str) => ('0' + str).slice(-2)

</script>

<style>
</style>
