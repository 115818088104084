<template lang="pug">
  //- "small" thumb left/right (reverse) logic handled in <style></style> tag below !!!!
  //- router-link.programme-entry-thumb.w-full.cursor-pointer.flex(
  //-   v-if="entry"
  //-   :to="rt"
  //-   :class="{'flex-col md_flex-row': !size, 'md_flex-row-reverse': reverse}"
  //-   :data-size="size"
  //- )
  component.programme-entry-thumb.w-full.cursor-pointer.flex(
    v-if="entry"
    :is="entry.externalLink ? 'a' : 'router-link'"
    :to="entry.externalLink ? null : rt"
    :href="entry.externalLink ? entry.externalLink : null"
    :target='entry.externalLink ? "_blank" : undefined'
    :class="{'flex-col md_flex-row': !size, 'md_flex-row-reverse': reverse}"
    :data-size="size"
  )
    //- white text block
    header.w-1x2.p-20.md_p-40(:class="{'w-full md_w-1x2 order-last md_order-none pb-60': !size}")
      //- (tags row)
      ul.flex.mb-20(v-if="hasTags", :class="{'md_mb-20': size !== 'small', 'md_mb-12': size === 'small'}")
        //- (section tag)
        li.mr-8.md_mr-15(v-if="sectionTag")
          router-link(:to="{name: 'programme-section', params: {section: entry.section}}",  :class="{'pointer-events-none': isActive(entry)}")
            btn-boxed(:size="size", hoverRound="1", :active="isActive(entry)") {{ sectionTag }}

        //- (tags...)
        //- pre {{ entry }}
        li.mr-8.md_mr-15(v-for="tag in entry.tags.slice(0, tagLimit)")
          router-link(:to="{name: 'programme-tagged', params: {tag: tag.slug}}")
            btn-boxed.capitalize(:size="size", hoverRound="1", :active="$route.params.tag === tag.slug") {{ tag.title }}

        //- (now|next|past)
        //- li(v-if="dateTag")
          router-link(:to="`/programme/${dateTag.toLowerCase()}`")
            btn-boxed.capitalize(:size="size", hoverRound="1", :active="routePathEnd === dateTag") {{ dateTag }}

      //- title
      title-rich(:class="type")
        template(v-if="entry.titleRich")
          h4(v-html="entry.titleRich")
        template(v-else-if="entry.artist")
          h4
            template(v-if="entry.artist") {{ entry.artist }}<br>
            | <b>{{entry.title}}</b>
        div(v-if="datesVisible")
          template(v-if="entry.dateCustomText") {{ entry.dateCustomText }}
          template(v-else) {{ $store.getters.dateSpan(entry.dateStart, entry.dateEnd) }}

    //- image block
    figure.flex-1.bg-gray-50.relative
      .pb-ar-1x1
        image-video-clip(:media="indexImage", :bg="true")
        //- resp-img(:bg="true", :image="entry.indexImage[0]", v-if="entry.indexImage[0]")
</template>

<script>
export default {
  name: 'PressEntryThumb',
  props: {
    entry: Object,
    reverse: { type: Boolean, default: false },
    size: String, // 'small' (half-width), or default: large (full-width)
    dates: { type: Boolean, default: true }
  },
  computed: {
    rt () {
      // collection item ?
      // if (this.entry.section === 'collection') {
      //   return { name: 'collection-item', params: { collectionItem: this.entry.slug } }
      // }
      // programme
      // return { name: 'press-entry', params: { entry: this.entry.slug, section: this.entry.section } }
      // return
      let link = ''
      if (this.entry.externalLink) {
        link = this.entry.externalLink
      } else {
        link = '/press/' + this.entry.slug
      }
      return link
    },
    type () {
      return this.size === 'small' ? 'text-13 md_text-16 xl_text-20'
        : 'text-18 md_text-20 xl_text-24'
    },
    indexImage () {
      const firstSlide = this.entry.firstSlide && this.entry.firstSlide[0]?.image[0]
      return this.entry.indexImage[0] || firstSlide
    },
    hasTags () {
      return (this.entry.tags && this.entry.tags.length) || this.sectionTag || this.dateTag
    },
    sectionTag () {
      // only certain sections are tagged
      const sections = {
        exhibitions: 'Exhibition',
        performances: 'Performance',
        residencies: 'Residency'
        // podcasts: 'Podcast',
      }
      return sections[this.entry.section || 'na']
    },
    dateTag () {
      const today = new Date()
      const start = this.entry.dateStart && new Date(this.entry.dateStart)
      const end = this.entry.dateEnd && new Date(this.entry.dateEnd)
      return end && today > end ? 'past'
        : start && start > today ? 'next'
          : start && today >= start && end && today <= end ? 'now'
            : false
    },
    datesVisible () {
      return this.dates && this.entry.dateCustomText !== '0'
    },
    tagLimit () {
      let limit = this.sectionTag && this.dateTag ? 1
        : this.sectionTag || this.dateTag ? 2
          : 3
      if (this.size === 'small') {
        limit--
      }
      return limit
    },
    routePathEnd () {
      return this.$route.path.split('/').pop()
    }
  },
  methods: {
    isActive ({ section }) {
      return this.$route.params.section === section
    }
  }
}
</script>

<style>
/* mobile: small thumbs always alternate */
@media (max-width: 567px) {
  .programme-entry-thumb[data-size="small"] {
    &:nth-child(2n) {
      @apply flex-row-reverse;
    }
  }
}

/* alternate small based on class (for Search and RelatedPosts) */
@media (min-width:568px) {
  .entries-small-alternate .programme-entry-thumb[data-size="small"]{
    &:nth-child(4n + 3),
    &:nth-child(4n + 4) {
      @apply flex-row-reverse;
    }
  }
}
</style>
